import React from 'react'
import Header from '../components/Header'

function BlogDetail({ blog }) {
  return (
    <div className='w-full h-auto bg-black flex flex-col items-center scroll-smooth'>
      <Header />
      <div className='w-full mt-40 h-auto min-h-screen flex justify-center'>
        <div className='w-2/3 max-sm:11/12 max-md:w-10/12 h-auto'>
        <img
        src={blog.image}
        alt='blogimage'
        className='w-full h-[50vh] max-sm:h-[25vh] object-cover rounded-lg'
        />
        <div className='w-full py-2 text-[15px] text-white/70 pt-8 leading-none'><i class="fa-regular fa-clock mr-2"></i>{blog.tarih}</div>
        <div className='w-full py-2 text-[33px] font-semibold text-white leading-none'>{blog.title}</div>
        <div className='w-full py-2 text-xl text-white/80' dangerouslySetInnerHTML={{ __html: blog.content }}></div>
        <div className='w-full py-8 flex flex-wrap items-center gap-2 *:px-4 *:py-1 *:bg-white/20 *:text-xs *:text-white *:rounded-full *:cursor-pointer'>
          {blog.tags.map((t, index) => (
            <div key={index}>{t}</div>
          ))}
        </div>
        </div>
      </div>

    </div>
  )
}

export default BlogDetail