import React, { useEffect } from 'react'
import "./home.css"
import { ReactTyped } from 'react-typed'
import Work from './Work'
import 'aos/dist/aos.css';
import AOS from 'aos';
import Blog from './Blog';
import Contact from './Contact';
import Header from '../components/Header';

function Home() {

    

    useEffect(() => {
        AOS.init({ duration: 800 });
    }, []);

    return (
        <div className='w-full h-auto  bg-black flex flex-col items-center scroll-smooth'>
            <Header />
            <div className='w-full h-[90vh] bg-black flex max-sm:flex-col items-center max-sm:justify-center'>
                <div className='w-3/12 max-sm:hidden'></div>
                <div className='w-2/12 max-sm:w-full py-2 max-sm:px-6 font-inter text-white text-custom font-normal text-start'>Web Geliştirme <br /> Grafik Tasarım <br /> Yaratıcı Yazarlık</div>
                <ReactTyped className='w-7/12 max-sm:w-full max-sm:h-[40vh] max-sm:flex max-sm:items-start max-sm:pl-6 max-sm:pr-6 pr-20 h-52 font-inter text-white text-[44px] leading-[48px] font-medium text-start'
                    showCursor={false}
                    strings={["Merhaba! Ben Emir, Samsun'da yaşıyorum. Front-End web geliştirme ve grafik tasarım üzerine çalışıyorum."]}
                    typeSpeed={20} />
            </div>


            <Work />

            <div data-aos="fade-up" className='border-t border-solid border-gray-700 w-[95%] h-auto'>
                <div className='w-full flex max-sm:flex-col font-inter'>
                    <div className='mt-8 text-custom text-gray-400 leading-none cursor-pointer'>2022</div>
                    <div className='mt-8 max-sm:mt-4 ml-8 max-sm:ml-0 text-[24px] font-semibold text-white leading-none cursor-pointer hover-underline-animation center'>Eco Banking App</div>
                    <div className='w-1/4 max-sm:w-full text-start ml-12 max-sm:ml-0 mt-8 max-sm:mt-2 text-custom text-white'>
                        Eco is revolutionizing the future of banking by merging sustainability with
                        cutting-edge technology.</div>
                    <div className='flex-1'></div>
                    <div className='mt-8 max-sm:mt-4 text-custom text-gray-400 leading-none'>Brand Design</div>

                </div>
                <img
                    src='/eco.avif'
                    alt='posttt'
                    className='w-full h-[85vh] max-sm:h-[30vh] object-cover mt-12 rounded-xl'
                />
            </div>


            <div data-aos="fade-up" className='w-[95%] h-auto'>
                <div className='w-full flex max-sm:flex-col font-inter'>
                    <div className='mt-8 text-custom text-gray-400 leading-none cursor-pointer'>2022</div>
                    <div className='mt-8 max-sm:mt-4 ml-8 max-sm:ml-0 text-[24px] font-semibold text-white leading-none cursor-pointer hover-underline-animation center'>Blake Burger</div>
                    <div className='w-1/4 max-sm:w-full text-start ml-12 max-sm:ml-0 mt-8 max-sm:mt-2 text-custom text-white'>
                        Blake Burger brings you a bold and unforgettable taste experience with every bite. Crafted from the finest ingredients and packed with flavor, Blake Burger is where quality meets indulgence.
                    </div>
                    <div className='flex-1'></div>
                    <div className='mt-8 max-sm:mt-4 text-custom text-gray-400 leading-none'>Advertisement Design</div>

                </div>
                <img
                    src='/blake.avif'
                    alt='posttt'
                    className='w-full h-[85vh] max-sm:h-[30vh] object-cover mt-12 rounded-xl'
                />
            </div>









            <div data-aos="fade-up" className='w-[95%] h-auto'>
                <div className='w-full flex max-sm:flex-col font-inter'>
                    <div className='mt-8 text-custom text-gray-400 leading-none cursor-pointer'>2022</div>
                    <div className='mt-8 max-sm:mt-4 ml-8 max-sm:ml-0 text-[24px] font-semibold text-white leading-none cursor-pointer hover-underline-animation center'>Flee Mobile Health</div>
                    <div className='w-1/4 max-sm:w-full text-start ml-12 max-sm:ml-0 mt-8 max-sm:mt-2 text-custom text-white'>
                        Flee Mobile Health is transforming healthcare access by bringing essential medical services right to your fingertips.
                    </div>
                    <div className='flex-1'></div>
                    <div className='mt-8 max-sm:mt-4 text-custom text-gray-400 leading-none'>User Interface Design</div>

                </div>
                <img
                    src='/flee.avif'
                    alt='posttt'
                    className='w-full h-[85vh] max-sm:h-[30vh] object-cover mt-12 rounded-xl'
                />
            </div>







            <div data-aos="fade-up" className='w-[95%] h-auto'>
                <div className='w-full flex max-sm:flex-col font-inter'>
                    <div className='mt-8 text-custom text-gray-400 leading-none cursor-pointer'>2023</div>
                    <div className='mt-8 max-sm:mt-4 ml-8 max-sm:ml-0 text-[24px] font-semibold text-white leading-none cursor-pointer hover-underline-animation center'>Missing Eye</div>
                    <div className='w-1/4 max-sm:w-full text-start ml-12 max-sm:ml-0 mt-8 max-sm:mt-2 text-custom text-white'>
                        Missing Eye captivates with its haunting narrative, and the poster design brings that eerie mystery to life.
                    </div>
                    <div className='flex-1'></div>
                    <div className='mt-8 max-sm:mt-4 text-custom text-gray-400 leading-none'>Poster Design</div>

                </div>
                <img
                    src='/missingeye.avif'
                    alt='posttt'
                    className='w-full h-[85vh] max-sm:h-[30vh] object-cover mt-12 rounded-xl'
                />
            </div>













            <div data-aos="fade-up" className='w-[95%] h-auto'>
                <div className='w-full flex max-sm:flex-col font-inter'>
                    <div className='mt-8 text-custom text-gray-400 leading-none cursor-pointer'>2022</div>
                    <div className='mt-8 max-sm:mt-4 ml-8 max-sm:ml-0 text-[24px] font-semibold text-white leading-none cursor-pointer hover-underline-animation center'>Alive</div>
                    <div className='w-1/4 max-sm:w-full text-start ml-12 max-sm:ml-0 mt-8 max-sm:mt-2 text-custom text-white'>
                        Alive takes the zombie genre to new heights, and the movie banner design captures the intense, heart-pounding action of survival.                                                            </div>
                    <div className='flex-1'></div>
                    <div className='mt-8 max-sm:mt-4 text-custom text-gray-400 leading-none'>Movie Banner Design</div>

                </div>
                <img
                    src='/alive.avif'
                    alt='posttt'
                    className='w-full h-[85vh] max-sm:h-[30vh] object-cover mt-12 rounded-xl'
                />
            </div>









            <div data-aos="fade-up" className='w-[95%] h-auto'>
                <div className='w-full flex max-sm:flex-col font-inter'>
                    <div className='mt-8 text-custom text-gray-400 leading-none cursor-pointer'>2022</div>
                    <div className='mt-8 max-sm:mt-4 ml-8 max-sm:ml-0 text-[24px] font-semibold text-white leading-none cursor-pointer hover-underline-animation center'>Buzz Social Media App</div>
                    <div className='w-1/4 max-sm:w-full text-start ml-12 max-sm:ml-0 mt-8 max-sm:mt-2 text-custom text-white'>
                        Buzz is redefining social connection by creating a vibrant, dynamic space for real-time conversations and community building.                                                            </div>
                    <div className='flex-1'></div>
                    <div className='mt-8 max-sm:mt-4 text-custom text-gray-400 leading-none'>Brand Design</div>

                </div>
                <img
                    src='/buzz.avif'
                    alt='posttt'
                    className='w-full h-[85vh] max-sm:h-[30vh] object-cover mt-12 rounded-xl'
                />
            </div>








            <div data-aos="fade-up" className='w-[95%] h-auto'>
                <div className='w-full flex max-sm:flex-col font-inter'>
                    <div className='mt-8 text-custom text-gray-400 leading-none cursor-pointer'>2023</div>
                    <div className='mt-8 max-sm:mt-4 ml-8 max-sm:ml-0 text-[24px] font-semibold text-white leading-none cursor-pointer hover-underline-animation center'>Devil</div>
                    <div className='w-1/4 max-sm:w-full text-start ml-12 max-sm:ml-0 mt-8 max-sm:mt-2 text-custom text-white'>
                        Devil delves into the dark and twisted tale of a woman who makes a pact with the devil, trading her soul for power and desire.                                                            </div>
                    <div className='flex-1'></div>
                    <div className='mt-8 max-sm:mt-4 text-custom text-gray-400 leading-none'>Movie Banner Design</div>

                </div>
                <img
                    src='/devil.avif'
                    alt='posttt'
                    className='w-full h-[85vh] max-sm:h-[30vh] object-cover mt-12 rounded-xl'
                />
            </div>




            <Blog />
            <Contact />

           
        </div>
    )
}

export default Home