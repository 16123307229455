import { Pivot as Hamburger } from 'hamburger-react'
import React, { useState } from 'react'
import { Link } from 'react-scroll';

function Header() {

  const [isOpen, setOpen] = useState(false)

  return (
    <nav className='w-full py-4 max-sm:px-8 mx-4 flex fixed mix-blend-difference max-sm:mix-blend-normal z-50'>
      <div className='w-3/12 px-16 py-2 flex font-inter text-white text-custom font-semibold max-sm:hidden'>Emirhan Körhan</div>
      <div className='w-2/12 py-2 font-inter text-white text-custom font-medium text-start max-sm:hidden'>Samsun, Turkey <br /> Front-End Developer</div>
      <div className='w-1/2 items-center justify-center max-sm:justify-start hidden max-sm:flex z-40'>
        <Hamburger color='#ffffff' toggled={isOpen} toggle={setOpen} rounded size={25}></Hamburger>

      </div>
      <div className='w-5/12 pr-40 py-2 flex-1 flex justify-evenly gap-10 *:font-medium *:cursor-pointer *:font-inter *:text-white *:text-xs max-sm:hidden'>
        <div className='hover:text-gray-300'>Hakkımda</div>
        <div className='hover:text-gray-300'>Galeri</div>
        <div className='hover:text-gray-300'>Blog</div>
        <div className='hover:text-gray-300'>İletişim</div>
      </div>

      <div className='w-2/12 max-sm:w-1/2 flex items-center justify-center max-sm:justify-end group'>
        <div className='relative w-10 h-10 flex items-center justify-center z-40'>
          <img
            src='/profile.jpg'
            alt='photo222'
            className='absolute w-10 h-10 rounded-full'
          />
          <div className='absolute top-16 text-2xs py-1 px-4 bg-white/15 rounded-md font-inter cursor-pointer text-white mb-12 opacity-0 transition-opacity duration-300 group-hover:opacity-100'>
            Open for new work
          </div>
          <div className='absolute w-3 h-3 bg-green-500 bottom-0 left-0 rounded-full'></div>
          <div className='absolute w-3 h-3 bg-green-500 bottom-0 left-0 rounded-full animate-ping'></div>
        </div>

        {isOpen && (<div className="w-[200rem] h-[200rem] rounded-full fixed -top-[100rem] -left-[100rem] flex items-center justify-center bg-black anmt">
          <div className="w-full h-screen flex flex-col items-center justify-center fixed top-0 left-0">
            <div className='w-2/3 h-auto *:font-inter *:text-white flex flex-col *:flex *:items-center *:justify-start *:text-4xl *:font-semibold tag-menu'>
              <Link to="about" spy={true} smooth={true} duration={500}> <button >HAKKIMDA</button></Link>
              <Link to="project" spy={true} smooth={true} duration={500}><button >GALERİ</button></Link>
              <Link to="blog" spy={true} smooth={true} duration={500}><button >BLOG</button></Link>
              <Link to="contact" spy={true} smooth={true} duration={500}><button>İLETİŞİM</button></Link>
            </div>
            <div className='w-full py-8 absolute bottom-12 flex items-center justify-between *:text-white *:font-inter px-8 tag-menu text-custom'>
              <div className=''>Samsun, Turkey</div>
              <div className='flex items-center gap-4'>
                <div><a href='https://www.instagram.com/emir.krhan'>ig</a></div>
                <div><a href='https://www.behance.net/emirhankrhan'>bh</a></div>
                <div><a href='https://github.com/emirhankorhan'>gh</a></div>
              </div>

            </div>
            <div className="space-bott"></div>
          </div>
        </div>)}


      </div>
    </nav>
  )
}

export default Header