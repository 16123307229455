import React, { useEffect } from 'react'
import 'aos/dist/aos.css';
import AOS from 'aos';

function Blog() {

    useEffect(() => {
        AOS.init({ duration: 800 });
    }, []);

    return (
        <div className='w-full h-auto flex flex-col items-center'>
            <div data-aos="fade-up" className='w-[95%] px-16 max-sm:px-2 pt-40 pb-20 text-[100px] max-sm:text-[60px] text-start leading-[100px] max-sm:leading-[55px] text-white font-inter font-medium'>BİRKAÇ <br /> BLOG YAZISI</div>

            <div className='w-[95%] h-auto'>
                <img
                    data-aos="fade-up"
                    src='/blog1.avif'
                    alt='posttt'
                    className='w-full h-[85vh] max-sm:h-[30vh] object-cover mt-12 rounded-xl'
                />
                <div data-aos="fade-up" className='w-full font-inter'>
                    <div className='mt-8 text-custom text-gray-300 leading-none flex items-center'><i class="fa-solid fa-circle fa-fade text-2xs mr-2 text-red-600"></i> GÜNCEL İÇERİK</div>
                    <div className='flex items-center justify-start gap-2 pt-4 pb-2 max-sm:py-6 *:px-4 *:py-1 *:bg-white/20 *:text-2xs *:text-white *:rounded-full *:cursor-pointer'>
                        <div>Teknoloji</div>
                        <div>Veri</div>
                        <div>Yapay</div>
                        <div>Zeka</div>
                    </div>

                    <div className='flex items-center justify-between'>
                        <a href='/blog/aw7d6y8398ıjawdj89374y957wt4uoıgjfıoq028r' className='text-[33px] font-semibold text-white leading-none cursor-pointer'>Yazılım ve Teknolojinin Geleceği: Yapay Zeka ve Makine Öğrenimi</a>
                        <button className='w-12 h-12 bg-white rounded-full hover:bg-gray-300 transition duration-300 ease-in-out max-sm:hidden'>
                            <i class="fa-solid fa-play"></i>
                        </button>
                    </div>
                    <div className='text-start my-4 text-custom text-white max-sm:line-clamp-5'>
                        Yazılım ve teknoloji dünyası, sürekli değişen dinamikleriyle her geçen gün yeni fırsatlar
                        ve zorluklar sunuyor. Özellikle yapay zeka (AI) ve makine öğrenimi (ML) alanları, bu değişimin
                        en belirgin örneklerinden biri. Bu yazıda, yapay zekanın geleceğini ve hayatımız üzerindeki etkilerini
                        inceleyeceğiz. Yapay zeka, insan benzeri düşünme ve öğrenme yeteneklerine sahip sistemler geliştirmeyi
                        amaçlayan bir teknoloji dalıdır. Makine öğrenimi, yapay zekanın bir alt dalıdır ve verilerden öğrenerek
                        karar verme süreçlerini otomatikleştirir. Günümüzde bu teknolojiler, sağlık hizmetlerinden finans sektörüne,
                        eğitimden otomotive kadar birçok alanda kullanılmaktadır.
                    </div>
                    <div className='flex-1'></div>
                    <div className='mt-8 flex items-center justify-center'>

                    </div>
                </div>
            </div>









            <div className='w-[95%] h-auto'>
                <img
                    data-aos="fade-up"
                    src='/blog2.avif'
                    alt='posttt'
                    className='w-full h-[85vh] max-sm:h-[30vh] object-cover mt-12 rounded-xl'
                />
                <div data-aos="fade-up" className='w-full font-inter'>
                    <div className='mt-8 text-custom text-gray-300 leading-none flex items-center'><i class="fa-solid fa-circle fa-fade text-2xs mr-2 text-red-600"></i> GÜNCEL İÇERİK</div>
                    <div className='flex items-center justify-start gap-2 pt-4 pb-2 max-sm:py-6 *:px-4 *:py-1 *:bg-white/20 *:text-2xs *:text-white *:rounded-full *:cursor-pointer'>
                        <div>Grafik</div>
                        <div>Tasarım</div>
                        <div>Estetik</div>
                        <div>Görsel</div>
                    </div>

                    <div className='flex items-center justify-between'>
                        <a href='/blog/op4d6y8398ıjawdj89374y235wt4uoıgjfıoq090o' className='text-[33px] font-semibold text-white leading-none cursor-pointer'>Grafik Tasarımı ve Estetik: Görsel Dünyamızın Dili</a>
                        <button className='w-12 h-12 bg-white rounded-full hover:bg-gray-300 transition duration-300 ease-in-out max-sm:hidden'>
                            <i class="fa-solid fa-play"></i>
                        </button>
                    </div>
                    <div className='text-start my-4 text-custom text-white max-sm:line-clamp-5'>
                        Günümüz dijital çağında, grafik tasarım hayatımızın her alanında önemli bir rol oynamaktadır.
                        Bir ürünün ambalajından bir web sitesinin tasarımına kadar, grafik tasarım estetik unsurların
                        bir araya gelerek iletmek istediğimiz mesajı etkili bir şekilde sunma konusunda kritik bir öneme
                        sahiptir. Bu yazıda grafik tasarımın temel unsurlarını, estetik değerlerini ve gelecekteki eğilimlerini inceleyeceğiz.
                        Grafik tasarım, metin ve görüntülerin birleşimiyle bilgi ve mesaj iletmeyi amaçlayan bir sanat ve bilim
                        dalıdır. Bu alandaki tasarımcılar, estetik anlayışlarını kullanarak, belirli bir hedef kitleye hitap eden görsel
                        iletişim araçları oluştururlar. Grafik tasarımın temel unsurları şunlardır:


                    </div>
                    <div className='flex-1'></div>
                    <div className='mt-8 flex items-center justify-center'>

                    </div>
                </div>
            </div>























            <div className='w-[95%] h-auto'>
                <img
                    data-aos="fade-up"
                    src='/blog3.avif'
                    alt='posttt'
                    className='w-full h-[85vh] max-sm:h-[30vh] object-cover mt-12 rounded-xl'
                />
                <div data-aos="fade-up" className='w-full font-inter'>
                    <div className='mt-8 text-custom text-gray-300 leading-none flex items-center'><i class="fa-solid fa-circle fa-fade text-2xs mr-2 text-red-600"></i> GÜNCEL İÇERİK</div>
                    <div className='flex items-center justify-start gap-2 pt-4 pb-2 max-sm:py-6 *:px-4 *:py-1 *:bg-white/20 *:text-2xs *:text-white *:rounded-full *:cursor-pointer'>
                        <div>UI</div>
                        <div>Kullanıcı</div>
                        <div>Deneyim</div>
                        <div>Dijital</div>
                        <div>Tasarım</div>
                    </div>

                    <div className='flex items-center justify-between'>
                        <a href='/blog/9tme4y8398ıku7tj89374y235wt4uoıgwjum57yuu' className='text-[33px] font-semibold text-white leading-none cursor-pointer'>Front-End Geliştirme ve UI Tasarımı: Kullanıcı Deneyiminin Temel Taşları                        

                        </a>
                        <button className='w-12 h-12 bg-white rounded-full hover:bg-gray-300 transition duration-300 ease-in-out max-sm:hidden'>
                            <i class="fa-solid fa-play"></i>
                        </button>
                    </div>
                    <div className='text-start my-4 text-custom text-white max-sm:line-clamp-5'>
                        Dijital dünyanın giderek daha fazla hayatımızın merkezine yerleşmesiyle birlikte, kullanıcı deneyimi (UX) ve
                        kullanıcı arayüzü (UI) tasarımı, web ve mobil uygulama geliştirme süreçlerinin vazgeçilmez unsurları haline geldi.
                        Front-end geliştirme, bir uygulamanın veya web sitesinin görünümünü ve etkileşimini şekillendiren süreci kapsarken,
                        UI tasarımı, kullanıcıların bir ürünle etkileşimde bulunurken yaşadığı deneyimi estetik açıdan çekici hale getiren
                        unsurlardır. Bu yazıda, front-end geliştirme ve UI tasarımının önemini, temel unsurlarını ve gelecekteki
                        trendlerini inceleyeceğiz.
                    </div>
                    <div className='flex-1'></div>
                    <div className='mt-8 flex items-center justify-center'>

                    </div>
                </div>
            </div>


        </div>
    )
}

export default Blog