import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import BlogDetail from './pages/BlogDetail';
import { blogData1, blogData2, blogData3 } from './components/Blogs';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/blog/aw7d6y8398ıjawdj89374y957wt4uoıgjfıoq028r' element={<BlogDetail blog={blogData1} />} />
        <Route path='/blog/op4d6y8398ıjawdj89374y235wt4uoıgjfıoq090o' element={<BlogDetail blog={blogData2} />} />
        <Route path='/blog/9tme4y8398ıku7tj89374y235wt4uoıgwjum57yuu' element={<BlogDetail blog={blogData3} />} />


      </Routes>
      <div className='w-full h-auto flex items-center justify-center bg-black'>
      <div className='w-[95%] py-20 flex items-center justify-between '>
        <div className='w-auto font-inter text-white text-xs max-sm:text-3xs'>© 2023 All Rights Reserved &nbsp; <i class="fa-solid fa-certificate"></i> &nbsp; Created by EmirKrhn</div>
        <div className='w-1/6 max-sm:w-1/3 py-4 flex items-center justify-between gap-2 *:text-white'>
          <a href='https://www.instagram.com/emir.krhan' className='w-10 h-10 hover:bg-white/10 transition duration-500 ease-in-out rounded-full flex items-center justify-center'>
            <img
              src='/instagram.svg'
              alt='instagram'
              className='w-5 h-5 object-cover'
            />
          </a>

          <a href='https://www.wattpad.com/user/sadecemeftun' className='w-10 h-10 hover:bg-white/10 transition duration-500 ease-in-out rounded-full flex items-center justify-center'>
            <img
              src='/wattpad.svg'
              alt='wattpad'
              className='w-5 h-5 object-cover'
            />
          </a>

          <a href='https://www.wattpad.com/user/sadecemeftun' className='w-10 h-10 hover:bg-white/10 transition duration-500 ease-in-out rounded-full flex items-center justify-center'>
            <img
              src='/behance.svg'
              alt='behance'
              className='w-5 h-5 object-cover'
            />
          </a>

          <a href='https://github.com/emirkrhan' className='w-10 h-10 hover:bg-white/10 transition duration-500 ease-in-out rounded-full flex items-center justify-center'>
            <img
              src='/github.svg'
              alt='github'
              className='w-5 h-5 object-cover'
            />
          </a>
        </div>
      </div>
      </div>
    </>
  );
}

export default App;
