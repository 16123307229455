import React, { useEffect } from 'react'
import 'aos/dist/aos.css';
import AOS from 'aos';

function Work() {

    useEffect(() => {
        AOS.init({ duration: 800 });
    }, []);

    return (
        <div className='w-[95%] h-auto px-16 max-sm:px-0'>
            <div data-aos="fade-up" className='w-full py-8 max-sm:px-2 max-sm:text-[60px] text-[100px] text-start leading-[100px] max-sm:leading-[55px] text-white font-inter font-medium'>GEÇMİŞ <br /> DENEYİMLER</div>
            <div className='w-full flex py-20'>
                <div className='w-2/5 max-sm:hidden'></div>
                <div className='w-3/5 max-sm:w-full h-auto'>
                    <div data-aos="fade-up" className='w-full py-8 h-auto *:font-inter border-b border-solid border-gray-700'>
                        <div className='w-full text-white text-[24px] font-medium text-start'>Freelance</div>
                        <div className='w-full text-white text-custom font-normal flex justify-between'>
                            <div>GRAPHIC DESIGNER</div>
                            <div>(2020 - Halen)</div>
                        </div>
                        <div className='w-2/3 text-custom font-normal text-start text-gray-400'>
                            Uzun süredir freelance graphic designer olarak
                            çalışıyorum, kitap kapakları ve reklam afişleri tasarlıyorum.
                        </div>
                    </div>


                    <div data-aos="fade-up" className='w-full py-8 h-auto *:font-inter border-b border-solid border-gray-700'>
                        <div className='w-full text-white text-[24px] font-medium text-start'>Bilgi İşlem Genel Müdürlüğü</div>
                        <div className='w-full text-white text-custom font-normal flex justify-between'>
                            <div>ENGINEER INTERN</div>
                            <div>(Haz - Ağu 2023)</div>
                        </div>
                        <div className='w-2/3 text-custom font-normal text-start text-gray-400'>
                            Bilgi İşlem Genel Müdürlüğü'nde yazılım mühendisi
                            olarak staj yaparak yazılım geliştirme ve test
                            süreçlerinde uygulamalı deneyim kazandım.
                        </div>
                    </div>


                    <div data-aos="fade-up" className='w-full py-8 h-auto *:font-inter'>
                        <div className='w-full text-white text-[24px] font-medium text-start'>Morla Moves BV.</div>
                        <div className='w-full text-white text-custom font-normal flex justify-between'>
                            <div>FRONT-END INTERN | QA TESTER</div>
                            <div>(Mar - Eyl 2024)</div>
                        </div>
                        <div className='w-2/3 text-custom font-normal text-start text-gray-400'>
                            Morla Moves şirketinde front-end intern ve QA
                            tester olarak çalıştım, kullanıcı arayüzü geliştirme
                            ve yazılım test süreçlerinde deneyim kazandım.
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Work